import { useEffect, useState } from "react";
import { imageBuilder } from "../lib/sanity";

/*  ------------------------------ */
/*  Generic helper functions
/*  ------------------------------ */

export const isBrowser = typeof window !== "undefined";

export function isMobileSafari() {
  if (!isBrowser) return;

  return !!(navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/));
}

export function measureHeight() {
  if (!isBrowser) return null;
  return window.innerHeight;
}

export function buildSrc(image, { width, height, format, quality }) {
  let imgSrc = imageBuilder.image(image);

  if (width) {
    imgSrc = imgSrc.width(Math.round(width));
  }

  if (height) {
    imgSrc = imgSrc.height(Math.round(height));
  }

  if (format) {
    imgSrc = imgSrc.format(format);
  }

  if (quality) {
    imgSrc = imgSrc.quality(quality);
  }

  return imgSrc.fit("max").auto("format").url();
}

export function buildSrcSet(image, { srcSizes, aspect, format, quality }) {
  const sizes = srcSizes.map((width) => {
    let imgSrc = buildSrc(image, {
      ...{ width },
      height: aspect && Math.round(width * aspect) / 100,
      ...{ format },
      ...{ quality },
    });

    if (format) {
      imgSrc = imgSrc.format(format);
    }

    return `${imgSrc} ${width}w`;
  });

  return sizes.join(",");
}

/**
 * Convert seconds to HH:MM:SS
 * If seconds exceeds 24 hours, hours will be greater than 24 (30:05:10)
 *
 * @param {number} seconds
 * @returns {string}
 */
export const getFormattedDuration = (seconds) => {
  const SECONDS_PER_DAY = 86400;
  const HOURS_PER_DAY = 24;

  const days = Math.floor(seconds / SECONDS_PER_DAY);
  const remainderSeconds = seconds % SECONDS_PER_DAY;
  const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
  const hmsStr = hms.replace(/^(\d+)/, (h) => `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, "0"));

  return seconds > 3600 ? hmsStr : hmsStr.substring(3);
};

// TODO: Improve handling
export const buildCloudinaryURL = (cloudinaryPath, transformations = "f_auto,q_auto") => {
  return `https://res.cloudinary.com/build-appeal/image/upload/${transformations}/v1/ba-media/${
    process.env.NEXT_PUBLIC_STAGE || "staging"
  }/${cloudinaryPath}`;
};

export function useFullScreen() {
  const [height, setHeight] = useState(measureHeight);

  const wasRenderedOnClientAtLeastOnce = useWasRenderedOnClientAtLeastOnce();

  useEffect(() => {
    if (!wasRenderedOnClientAtLeastOnce) return;

    function setMeasuredHeight() {
      const measuredHeight = measureHeight();
      setHeight(measuredHeight);
    }

    window.addEventListener("resize", setMeasuredHeight);
    return () => window.removeEventListener("resize", setMeasuredHeight);
  }, [wasRenderedOnClientAtLeastOnce]);
  return wasRenderedOnClientAtLeastOnce ? height : null;
}

function useWasRenderedOnClientAtLeastOnce() {
  const [wasRenderedOnClientAtLeastOnce, setWasRenderedOnClientAtLeastOnce] = useState(false);

  useEffect(() => {
    if (isBrowser) {
      setWasRenderedOnClientAtLeastOnce(true);
    }
  }, []);
  return wasRenderedOnClientAtLeastOnce;
}

export const getFileBlob = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const blobData = new Blob([new Uint8Array(reader.result)], { type: file.type });
      resolve(blobData);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

export const getFileURL = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file?.file);
  });
};

export const sortFeaturedPictures = (pics) => {
  if (!pics?.length) return [];
  const otherImg = [];
  const featuredImg = [];
  pics.forEach((pic) => {
    if (!pic.alt) pic.alt = pic.project?.title ? pic.project?.title.split(",")[0] : "";
    pic.isFeatured ? featuredImg.push(pic) : otherImg.push(pic);
  });
  return [...featuredImg, ...otherImg];
};

export const formatCurrency = (number) => {
  if (!number) {
    return "$0.00";
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  return formatter.format(number);
};
