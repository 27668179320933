import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import Picture from "components/picture";
import CustomLink from "components/link";
import { UimStar as StarIcon } from "@iconscout/react-unicons-monochrome";
import CoreContainer from "../core-container";

const Quote = ({ data = {} }) => {
  const { video, photo, quote, quoteName = "", rating, link, theme } = data;
  return (
    <CoreContainer theme={theme?.value} mobilePadding={true}>
      <div
        className={cx(
          "mx-auto mb-0 flex flex-col rounded-sm bg-[#EEEEE2] md:mb-20   lg:flex-row",
          video || photo ? "w-full" : "max-w-[680px]"
        )}
      >
        {(video || photo) && (
          <div className="w-full self-center lg:w-[400px] lg:flex-shrink-0 lg:p-0">
            {photo ? (
              <Picture picture={{ image: photo }} layout="responsive" sizes="(max-width: 942px) 100vw, 25vw" />
            ) : null}
          </div>
        )}
        <div
          className={cx("px-9 py-9 lg:px-32 lg:py-20", video ? "lg:flex lg:flex-col lg:justify-center lg:py-0" : "")}
        >
          <StarRating rating={rating} />
          <div className="mt-8  text-sm font-normal text-accent-700 lg:text-xl">&Prime;{quote}&Prime;</div>
          <div className="before:content-['\'] mt-6 flex items-center text-md font-light before:mt-0.5 before:mr-2 before:h-0.5 before:w-5 before:bg-accent-700 lg:mt-16">
            {quoteName}
          </div>
          {link?.length > 0 && (
            <div className="mt-14">
              <CustomLink {...link} />
            </div>
          )}
        </div>
      </div>
    </CoreContainer>
  );
};

const StarRating = ({ rating }) => {
  const num = parseInt(rating, 10);
  if (isNaN(num) || num <= 0) return null;
  return (
    <div className="text-[#D89F39]">
      {Array.from(Array(num).keys()).map((i) => (
        <StarIcon key={i} width="24" height="24" />
      ))}
    </div>
  );
};

Quote.propTypes = {
  video: PropTypes.string,
  photo: PropTypes.shape({
    _type: PropTypes.string,
    asset: PropTypes.shape({
      _type: PropTypes.string,
      _ref: PropTypes.string,
    }),
  }),
  quote: PropTypes.string,
  rating: PropTypes.number,
  quoteName: PropTypes.string,
  link: PropTypes.shape({
    shouldScroll: PropTypes.bool,
    newWindow: PropTypes.bool,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default Quote;
