import PropTypes from "prop-types";

const Brief = ({ data = {} }) => {
  const { title, features } = data;
  return (
    <div
      className={
        "mx-auto mb-12 mt-20 flex max-w-[678px] flex-col justify-between bg-accent-700 px-6 py-16 text-neutral-100 lg:flex-row lg:px-12"
      }
    >
      <div className="flex flex-row items-center justify-between lg:flex-col lg:items-start lg:justify-start">
        <div className="max-w-[200px] pr-4 text-left text-4xl font-normal lg:text-5xl">{title}</div>
        <div className="lg:mt-4">
          <BriefIcon />
        </div>
      </div>
      <div>
        {features?.map((feature, index) => (
          <div
            key={index}
            className="flex justify-between border-b border-b-neutral-100 border-opacity-20 py-4 first:pt-9 last:border-b-0 lg:first:pt-0"
          >
            <div className="pr-8 text-md font-light">{feature.label}</div>
            <div className="text-right">{feature.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const BriefIcon = () => {
  return (
    <svg width="66" height="63" viewBox="0 0 66 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.9087 37.4241L36.2956 38.6222H36.6284C36.9655 38.6224 37.2987 38.5502 37.6055 38.4103C37.9122 38.2705 38.1853 38.0664 38.4062 37.8118C38.6272 37.5572 38.7908 37.258 38.886 36.9347C38.9812 36.6113 39.0058 36.2712 38.9581 35.9375L37.7378 27.5728C37.627 26.8046 37.2778 26.0906 36.7394 25.5316L13.8862 2.65626C12.4475 1.21456 10.4949 0.403452 8.45815 0.401371C6.42136 0.399291 4.46716 1.20641 3.02546 2.64517C1.58376 4.08392 0.772653 6.03647 0.770573 8.07326C0.768492 10.1101 1.57561 12.0642 3.01437 13.5059L25.8675 36.3591C26.4127 36.9283 27.13 37.3025 27.9087 37.4241ZM35.4747 35.2053L28.2194 34.1181L8.25062 14.1494L14.33 7.87032L34.4541 28.0609L35.4747 35.2053ZM5.36624 5.05251C6.19262 4.25926 7.29373 3.8163 8.43921 3.8163C9.5847 3.8163 10.6858 4.25926 11.5122 5.05251L11.9781 5.49626L5.83218 11.6644L5.36624 11.1984C4.57299 10.3721 4.13003 9.27096 4.13003 8.12548C4.13003 6.97999 4.57299 5.87888 5.36624 5.05251Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M58.594 35.3163H41.9978C41.5565 35.3163 41.1332 35.4916 40.8211 35.8037C40.509 36.1158 40.3337 36.539 40.3337 36.9803C40.3337 37.4217 40.509 37.8449 40.8211 38.157C41.1332 38.4691 41.5565 38.6444 41.9978 38.6444H58.594C59.6851 38.7227 60.706 39.2114 61.4513 40.012C62.1966 40.8127 62.611 41.866 62.611 42.9599C62.611 44.0538 62.1966 45.107 61.4513 45.9077C60.706 46.7084 59.6851 47.1971 58.594 47.2753H26.8437C24.8165 47.2753 22.8723 48.0807 21.4389 49.5141C20.0054 50.9476 19.2001 52.8917 19.2001 54.9189C19.2001 56.9461 20.0054 58.8903 21.4389 60.3238C22.8723 61.7572 24.8165 62.5625 26.8437 62.5625H46.6128C47.0541 62.5625 47.4774 62.3872 47.7895 62.0751C48.1015 61.7631 48.2769 61.3398 48.2769 60.8985C48.2769 60.4571 48.1015 60.0339 47.7895 59.7218C47.4774 59.4097 47.0541 59.2344 46.6128 59.2344H26.8437C25.7021 59.2344 24.6073 58.7809 23.8001 57.9737C22.9928 57.1665 22.5394 56.0716 22.5394 54.93C22.5394 53.7884 22.9928 52.6936 23.8001 51.8864C24.6073 51.0792 25.7021 50.6257 26.8437 50.6257H58.594C60.55 50.5202 62.3912 49.669 63.7385 48.2471C65.0858 46.8252 65.8368 44.9409 65.8368 42.9821C65.8368 41.0232 65.0858 39.1389 63.7385 37.717C62.3912 36.2952 60.55 35.4439 58.594 35.3385V35.3163Z"
        fill="white"
        fillOpacity="0.1"
      />
    </svg>
  );
};

Brief.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        content: PropTypes.string,
      })
    ),
  }),
};

export default Brief;
