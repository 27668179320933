import React from "react";
import cx from "classnames";
import BlockContent from "@sanity/block-content-to-react";
import { UilCheck, UilTimes } from "@iconscout/react-unicons";
import Photo from "components/photo";
import CustomLink from "components/link";
import Title from "components/blocks/title";
import Brief from "components/blocks/brief";
import Quote from "components/blocks/quote";
import BeforePlusAfterSlider from "components/before-plus-after-slider";

export const blockSerializers = {
  types: {
    block: (props) => {
      const { markDefs, style = "normal", listItem = null } = props.node;
      // check if our block contains a button
      const hasButton = markDefs && markDefs.some((c) => c._type === "link" && c.isButton === true);
      // Handle all bullet list items in separate section
      if (listItem === "bullet") {
        return BlockContent.defaultSerializers.types.block(props);
      }
      // build our mock header styles
      if (style === "h1mock") {
        return (
          <h1
            className={cx("is-h1 mx-auto max-w-2xl py-4 text-4xl font-normal md:text-6xl", {
              "has-btn": hasButton,
            })}
          >
            {props.children}
          </h1>
        );
      }
      if (style === "bigmax") {
        return (
          <h1
            className={cx("is-bigmax m-bk max-w-[300px] pb-5 text-5xl font-normal", {
              "has-btn": hasButton,
            })}
          >
            {props.children}
          </h1>
        );
      }

      if (style === "h2mock") {
        return (
          <h2 className={cx("is-h2 max-w-lg pb-3 text-dh4 font-light", { "has-btn": hasButton })}>{props.children}</h2>
        );
      }

      if (style === "h3mock") {
        return (
          <h3
            className={cx("is-h3 pb-6  text-center text-xl font-light md:text-2xl", {
              "has-btn": hasButton,
            })}
          >
            {props.children}
          </h3>
        );
      }

      if (style === "h4mock") {
        return (
          <h4 className={cx("is-h4 value flex justify-center align-middle", { "has-btn": hasButton })}>
            {" "}
            <UilCheck
              className="mr-2 h-5 w-5 justify-center self-center rounded-full bg-white"
              fill="rgb(70, 63, 60);"
            />
            {props.children}
          </h4>
        );
      }

      // go through our remaining, true header styles
      if (/^h\d/.test(style)) {
        return React.createElement(style, { className: hasButton ? "has-btn" : null }, props.children);
      }
      // handle all other blocks with the default serializer
      return BlockContent.defaultSerializers.types.block(props);
    },
    photo: ({ node }) => {
      return <Photo photo={node} />;
    },
    title: ({ node }) => <Title data={node} />,
    brief: ({ node }) => <Brief data={node} />,
    quote: ({ node }) => <Quote data={node} />,
    beforePlusAfter: ({ node }) => <BeforePlusAfterSlider data={node} />,
    horizontalRule: () => <hr />,
  },
  list: (props) => {
    const isH4Mock = props.children?.[0]?.props?.node?.style === "h4mock";
    return isH4Mock ? (
      <div className={cx("list is-h4 value flex flex-col justify-center align-middle md:flex-row")}>
        {props.children}
      </div>
    ) : (
      <div className="list flex flex-col py-4">{props.children}</div>
    );
  },
  listItem: (props) => {
    const { style = "normal", listItem = "bullet" } = props?.node || {};
    let tag = "p";
    let fill = "#18414E";
    let rootClassName = "mt-4 flex flex-row";
    let iconClassName = "mr-2 h-7 w-7 justify-center self-center rounded-full bg-transparent";
    switch (style) {
      case "h1mock":
        tag = "h1";
        break;
      case "h2mock":
        tag = "h2";
        break;
      case "bigmax":
        tag = "p";
        break;
      case "h3mock":
        tag = "h3";
        break;
      case "h4mock":
        tag = "h4";
        fill = "rgb(70, 63, 60)";
        rootClassName = "mb-4 flex flex-row text-sm font-light md:mx-2 md:mb-0";
        iconClassName = "mr-2 h-5 w-5 justify-center self-center rounded-full bg-white";
        break;
      default:
        rootClassName = "mt-4 flex flex-row items-center";
        iconClassName = "mr-3 h-5 w-5 justify-center self-center rounded-full bg-[#DE7256] fill-[#fff] p-1";
        break;
    }
    return React.createElement(
      tag,
      {
        className: rootClassName,
      },
      <>
        {React.createElement(listItem === "close" ? UilTimes : UilCheck, {
          fill,
          className: iconClassName,
        })}
        {props.children}
      </>
    );
  },
  marks: {
    link: ({ mark, children }) => {
      const [title] = children;

      return (
        <CustomLink {...mark} url={mark.url || mark.href} title={mark.title || title}>
          {children}
        </CustomLink>
      );
    },
  },
};
