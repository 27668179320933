import PropTypes from "prop-types";

const Title = ({ data = {} }) => {
  const { subhead, title, caption, tags } = data;
  return (
    <div className="mx-auto flex flex-col mt-20 mb-20 lg:max-w-[678px] lg:px-0">
      <span className="px-1 text-xs font-medium text-accent-700 lg:px-2">{subhead}</span>
      <div className="mt-2.5 font-reckless text-5xl text-accent-900 lg:text-7xl lg:font-light">{title}</div>
      <div className="mt-5 px-1 text-md font-light text-accent-800 lg:mt-8 lg:px-2 lg:text-lg">{caption}</div>
      <div className="mt-5 flex flex-wrap lg:ml-2.5 lg:mt-10">
        {tags?.map((tag, index) => (
          <div
            key={index}
            className="mx-1 mt-2.5 rounded-full border border-accent-400 px-3 pt-1 pb-1.5 text-sm text-accent-700 last:mr-0"
          >
            {tag.label}
          </div>
        ))}
      </div>
    </div>
  );
};

Title.propTypes = {
  data: PropTypes.shape({
    subhead: PropTypes.string,
    title: PropTypes.string,
    caption: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      })
    ),
    className: PropTypes.string,
  }),
};

export default Title;
