import cx from "classnames";
import { config } from "lib/config";
import BlockContent from "@sanity/block-content-to-react";
import { blockSerializers } from "./block-serializers";
import "./block-content.css";

const Content = ({ blocks, className, theme }) => {
  if (!blocks) return null;

  return (
    <BlockContent
      renderContainerOnSingleChild
      className={cx(
        "block-content m-auto whitespace-normal text-lg font-light",
        className,
        theme ? `theme theme--${theme}` : null
      )}
      blocks={blocks}
      serializers={blockSerializers}
      projectId={config.projectId}
      dataset={config.dataset}
    />
  );
};

export default Content;
