import { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ReactCompareImage from "react-compare-image";
import { UilAngleLeft as ArrowLeft } from "@iconscout/react-unicons";
import { urlFor } from "lib/sanity";
import CoreContainer from "./core-container";

const LEFT_POSITION_LIMIT = 0.25;
const RIGHT_POSITION_LIMIT = 0.75;

const BeforePlusAfterSlider = ({ data = {} }) => {
  const { beforeImage, afterImage, text, subTitle, title, theme, beforeLabel = "Before", afterLabel = "After" } = data;

  const [position, setPosition] = useState(0.5);
  const beforeUrl = beforeImage ? urlFor(beforeImage).url() : null;
  const afterUrl = afterImage ? urlFor(afterImage).url() : null;

  if (!beforeUrl || !afterUrl) return null;

  return (
    <CoreContainer theme={theme?.value} className="pb-20">
      <section className="py-26 mx-auto px-10 pt-20 pb-2 text-center md:pt-28 md:pb-6">
        {subTitle ? (
          <p className="mb-2 whitespace-pre-wrap text-xs font-medium uppercase tracking-normal opacity-70">
            {subTitle}
          </p>
        ) : null}
        {title ? <h2 className="mb-4 text-4xl font-normal lg:text-5xl">{title}</h2> : null}
        {text ? <p className="mx-auto mb-6  text-lg font-light text-accent-700 md:max-w-2xl ">{text}</p> : null}
      </section>
      <div className="relative z-[1] mx-auto max-w-[1280px]">
        {position >= LEFT_POSITION_LIMIT && <Label label={beforeLabel} className="left-5" />}
        {position <= RIGHT_POSITION_LIMIT && <Label label={afterLabel} className="right-5" />}
        <ReactCompareImage
          handle={customHandle()}
          aspectRatio="wider"
          leftImage={beforeUrl}
          sliderLineWidth={3}
          rightImage={afterUrl}
          onSliderPositionChange={setPosition}
        />
      </div>
    </CoreContainer>
  );
};

const Label = ({ label, className }) => {
  return (
    <div
      className={cx(
        "absolute top-4 z-10 rounded-full bg-black bg-opacity-60 px-2 py-1 text-2xs font-normal text-neutral-100 lg:py-2 lg:px-5 lg:text-md",
        className
      )}
    >
      {label}
    </div>
  );
};

const customHandle = () => {
  return (
    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-accent-700 shadow-[0px_4px_4px_rgba(0,0,0,0.25)] lg:h-[4.5rem] lg:w-[4.5rem]">
      <ArrowLeft className="h-6 w-6 text-neutral-100" />
      <ArrowLeft className="h-6 w-6 rotate-180 text-neutral-100" />
    </div>
  );
};

BeforePlusAfterSlider.propTypes = {
  data: PropTypes.shape({
    beforeImage: PropTypes.shape({
      _type: PropTypes.string,
      asset: PropTypes.shape({
        _ref: PropTypes.string,
        _type: PropTypes.string,
      }),
    }),
    afterImage: PropTypes.shape({
      _type: PropTypes.string,
      asset: PropTypes.shape({
        _ref: PropTypes.string,
        _type: PropTypes.string,
      }),
    }),
    beforeLabel: PropTypes.string,
    afterLabel: PropTypes.string,
  }),
};

export default BeforePlusAfterSlider;
